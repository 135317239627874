<template>
  <div class="bg-white rounded-5 px-50 py-35">
    <div class="mb-25 flex justify-between">
      <div
        class="inline-flex items-center text-grey-fp-50 cursor-pointer"
        @click="router.push({ name: routesNames.admins })"
      >
        <AppIcon
          name="arrow-thin-left"
          class="mr-10"
        />
        <p class="text-md">Back to admins list</p>
      </div>
      <AppButton
        type="primary"
        class="mr-10 px-30"
        @click="onSave"
      >
        Save
      </AppButton>
    </div>

    <!-- F O R M -->
    <div class="py-10">
      <form
        class="max-w-625"
        @submit.prevent="onSave"
      >
        <div class="max-w-350">
          <AppInput
            v-model="v$.firstName.$model"
            label="First name"
            name="firstName"
            class="mb-20"
            maxlength="100"
            placeholder="First name"
            :error="errorMessages.firstName"
            @focus="clearErrorField('firstName')"
            @blur="validateField('firstName')"
          />

          <AppInput
            v-model="v$.lastName.$model"
            label="Last name"
            name="lastName"
            class="mb-20"
            placeholder="Last name"
            maxlength="100"
            :error="errorMessages.lastName"
            @focus="clearErrorField('lastName')"
            @blur="validateField('lastName')"
          />

          <AppInput
            v-model="v$.email.$model"
            label="Email"
            name="email"
            placeholder="Email"
            maxlength="140"
            :error="errorMessages.email"
            @focus="clearErrorField('email')"
            @blur="validateField('email')"
          />
        </div>
      </form>
    </div>
  </div>

  <!--  C O N F I R M A T I O N   M O D A L -->
  <portal
    v-if="isOpenConfirmationModal"
    to="admin"
  >
    <AppModal @cancel="closeConfirmation">
      <div class="w-full min-w-280 max-w-400">
        <p class="text-dark-cl-20 text-md mb-8">New user creation</p>
        <p class="text-grey-fp-60 mb-40">
          Creating a new user will give access to the
          Admin Portal and all of its functionality. Are you sure you want to create this new user?
        </p>
        <div class="flex items-center justify-end">
          <AppButton
            type="primary"
            size="mini"
            class="px-20 mr-10"
            @click="onSubmit"
          >
            Confirm
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20"
            @click="closeConfirmation"
          >
            Cancel
          </AppButton>
        </div>
      </div>
    </AppModal>
  </portal>

  <!-- U N S A V E D   C H A N G E S   H A N D L E R  -->
  <UnsavedChangesModal :disabled="disabledHandleChanges" :data="userForm" />
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, toRefs } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { useToast } from "vue-toastification";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { routesNames, router } from '@/router';
  import { userFormRules } from '@/views/admin/settings/validation-rules';
  import { IErrorFields, IAdminUserForm } from '@/types';
  import { handleSetErrors, validateField, clearErrorField } from '@/core/helper-functions';
  import { vuex } from '@/store';
  import { adminUsersService } from '@/services';

  export default defineComponent({
    name: 'AddAdmin',

    components: { AppIcon, AppInput, AppButton, AppModal, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const state = reactive({
        userForm: {
          firstName: '',
          lastName: '',
          email: ''
        } as IAdminUserForm
      });
      const disabledHandleChanges = ref<boolean>(false);

      const errorMessages = reactive<IErrorFields>({
        firstName: '',
        lastName: '',
        email: ''
      });

      const isOpenConfirmationModal = ref<boolean>(false);

      const { userForm } = toRefs(state);

      const v$ = useVuelidate(userFormRules, userForm.value);

      function openConfirmation() {
        isOpenConfirmationModal.value = true;
      }

      function closeConfirmation() {
        isOpenConfirmationModal.value = false;
      }

      async function onSave() {
        if (await v$.value.$validate()) {
          openConfirmation();
        } else { handleSetErrors(v$.value.$errors, errorMessages); }
      }
      
      async function onSubmit() {
        vuex.setLoading(true);

        await adminUsersService.createAdmin(userForm.value)
          .then(() => {
            toast.success('Admin user has been created.');
            closeConfirmation(); 
            disabledHandleChanges.value = true;
            router.push({ name: routesNames.admins });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      return {
        userForm,
        isOpenConfirmationModal,
        routesNames, 
        router,
        errorMessages,
        v$,
        disabledHandleChanges,

        onSave,
        onSubmit,
        closeConfirmation,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>