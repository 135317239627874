import { required, helpers, email } from "@vuelidate/validators";

const schoolsFormRules = {
  name: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  gradeFrom: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  gradeTo: {
    required: helpers.withMessage('This field cannot be empty', required)
  }
};

const categoriesFormRules = {
  name: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  iconName: {
    required: helpers.withMessage('This field cannot be empty', required)
  }
};

const userFormRules = {
  firstName: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  lastName: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  email: {
    required: helpers.withMessage('This field cannot be empty', required),
    email: helpers.withMessage('Email address is not valid', email)
  }
};

export { schoolsFormRules, categoriesFormRules, userFormRules };