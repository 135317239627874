
  import { defineComponent, ref, reactive, toRefs } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { useToast } from "vue-toastification";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { routesNames, router } from '@/router';
  import { userFormRules } from '@/views/admin/settings/validation-rules';
  import { IErrorFields, IAdminUserForm } from '@/types';
  import { handleSetErrors, validateField, clearErrorField } from '@/core/helper-functions';
  import { vuex } from '@/store';
  import { adminUsersService } from '@/services';

  export default defineComponent({
    name: 'AddAdmin',

    components: { AppIcon, AppInput, AppButton, AppModal, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const state = reactive({
        userForm: {
          firstName: '',
          lastName: '',
          email: ''
        } as IAdminUserForm
      });
      const disabledHandleChanges = ref<boolean>(false);

      const errorMessages = reactive<IErrorFields>({
        firstName: '',
        lastName: '',
        email: ''
      });

      const isOpenConfirmationModal = ref<boolean>(false);

      const { userForm } = toRefs(state);

      const v$ = useVuelidate(userFormRules, userForm.value);

      function openConfirmation() {
        isOpenConfirmationModal.value = true;
      }

      function closeConfirmation() {
        isOpenConfirmationModal.value = false;
      }

      async function onSave() {
        if (await v$.value.$validate()) {
          openConfirmation();
        } else { handleSetErrors(v$.value.$errors, errorMessages); }
      }
      
      async function onSubmit() {
        vuex.setLoading(true);

        await adminUsersService.createAdmin(userForm.value)
          .then(() => {
            toast.success('Admin user has been created.');
            closeConfirmation(); 
            disabledHandleChanges.value = true;
            router.push({ name: routesNames.admins });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      return {
        userForm,
        isOpenConfirmationModal,
        routesNames, 
        router,
        errorMessages,
        v$,
        disabledHandleChanges,

        onSave,
        onSubmit,
        closeConfirmation,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
